<template>
  <transition name="fade-transition">
    <div class="Project selector-margin-section" v-if="projectActive">
      <v-container class="px-8 px-sm-12 px-md-3 py-0">
        <v-row
          justify="space-between"
          class="animation"
          style="animation-delay: 1.3s"
        >
          <v-col
            cols="7"
            sm="6"
            class="Project--line-height-texts"
            v-bind:class="{
              'opacity-inactive': projectOpacity,
              'opacity-active': projectOpacity == false,
            }"
          >
            <div class="Project--paragraphs capitalize titles aeonik-bold word-spaces" v-html="project.name"></div>
            <div>
              <span class="capitalize titles aeonik-light">{{
                project.location
              }}</span>
            </div>
            <div>
              <span class="capitalize titles aeonik-light"
                >{{ numberWithCommas(project.meters)}}m<sup>2</sup></span
              >
            </div>
          </v-col>

          <!-- validar columna -->
          <router-link
            :to="'/project/' + projectNext.url"
            class="not-text-decoration color-black next-project"
            style="display: contents"
          >
            <v-col
              v-if="projectNext.url != ''"
              cols="5"
              sm="6"
              class="Project--line-height-texts Project__column-next"
              v-bind:class="{
                'opacity-active': projectOpacity,
                'opacity-inactive': projectOpacity == false,
              }"
              @mouseover="addOpacity"
              @mouseleave="removeOpacity"
            >
              <v-row justify="end" no-gutters>
                <v-col cols="auto">
                  <!-- inicia proyecto siguiente desk -->
                  <div class="d-none d-sm-block">
                    <div
                      class="Project--paragraphs capitalize titles aeonik-bold word-spaces"
                      v-html="projectNext.name"
                    ></div>
                    <div>
                      <span class="capitalize titles aeonik-light">{{
                        projectNext.location
                      }}</span>
                    </div>
                    <div>
                      <span class="capitalize titles aeonik-light"
                        >{{ numberWithCommas(projectNext.meters)}}m<sup>2</sup></span
                      >
                    </div>
                  </div>
                  <!-- /termina proyecto siguiente desk -->

                  <!-- inicia proyecto siguiente mobile -->
                  <!-- <div class="d-sm-none">
                    <span class="titles aeonik-bold">Next project</span>
                  </div> -->
                  <!-- /termina proyecto siguiente mobile -->
                </v-col>
              </v-row>
            </v-col>
          </router-link>

          <!-- /validar columna -->
        </v-row>

        <!-- **********************inicia categorías de proyecto**************** -->
        <v-row class="mb-sm-0 mb-md-5 animation" style="animation-delay: 1.3s">
          <v-col
            cols="auto"
            class="pt-0 pt-sm-1"
            v-bind:class="{
              'opacity-inactive': projectOpacity,
              'opacity-active': projectOpacity == false,
            }"
          >
            <span
              v-for="(category, cat) in project.categories"
              v-bind:key="cat"
              class="Project--categories capitalize aeonik-regular"
              >{{ category.name }}</span
            >
          </v-col>
        </v-row>
        <!-- **********************/termina categorías de proyecto**************** -->

        <!-- **********************inicia contenido del proyecto**************** -->
        <v-row
          v-for="(row, r) in project.content"
          v-bind:key="r"
          v-bind:class="selectClass(r)"
          v-bind:style="'animation-delay:1.6s;'"
          class="Project__row-content"
        >
          <v-col
            cols="12"
            v-for="(column, col) in row.value"
            v-bind:key="col"
            v-bind:md="12 / row.value.length"
            v-bind:class="{
              'opacity-inactive': projectOpacity,
              'opacity-active': projectOpacity == false,
            }"
          >
            <template v-for="(children, ch) in column.children">
              <div :key="ch + '1'">
                <template v-if="children.component == 'text'">
                  <div
                    class="wordBreak Project--paragraphs--content"
                    v-html="children.settings.text"
                  ></div>
                </template>
                <template v-else-if="children.component == 'image'">
                  <div>
                    <template v-if="children.settings.image != null">
                      <template v-if="isVideo(children.settings.image.path)">
                        <div
                          class="position-relative"
                          @click="
                            (project_modal = true),
                              sendProject(project),
                              sendPathAsset(children.settings.image.path)
                          "
                        >
                          <video
                            class="Project__row-content--video toClick"
                            muted
                          >
                            <source
                              type="video/mp4"
                              :src="
                                'https://www.ofisdesigncms.actstudio.xyz/' +
                                children.settings.image.path
                              "
                            />
                          </video>
                          <v-img
                            src="@/assets/icons/play.svg"
                            class="Project__row-content--play toClick"
                          ></v-img>
                        </div>
                      </template>
                      <template v-else>
                        <v-img
                          class="Project__row-content--image toClick"
                          :src="
                            'https://www.ofisdesigncms.actstudio.xyz/' +
                            children.settings.image.path
                          "
                          @click="
                            (project_modal = true),
                              sendProject(project),
                              sendPathAsset(children.settings.image.path)
                          "
                          position="bottom center"
                        ></v-img>
                      </template>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </v-col>
        </v-row>
        <!-- **********************/termina contenido del proyecto**************** -->

        <!-- **********************inicia proyectos relacionados**************** -->
        <v-row
          class="Project__row-related-projects animation-lazy d-none d-sm-flex mb-7 mb-xl-12 pb-xl-7"
        >
          <v-col
            cols="12"
            class="mt-md-5"
            v-bind:class="{
              'opacity-inactive': projectOpacity,
              'opacity-active': projectOpacity == false,
            }"
          >
            <span class="aeonik-bold titles">Proyectos relacionados</span>
          </v-col>
          <v-col
            cols="12"
            v-bind:class="{
              'opacity-inactive': projectOpacity,
              'opacity-active': projectOpacity == false,
            }"
          >
            <v-row>
              <v-col cols="12">
               <template v-if="render">
                  <carousel
                    :items="3"
                    :autoplay="false"
                    :dots="false"
                    :margin="50"
                    :autoHeight="true"
                    :nav="false"
                    :mouseDrag="false"
                    :responsive="{0:{items: 1}, 600:{items: 3}}"
                    slideTransition="linear"
                  >
                    <v-card
                      v-for="(project, prc) in projects"
                      v-bind:key="prc"
                      v-bind:to="'/project/'+project.url"
                      v-bind:ripple="false"
                      class=""
                      tile
                      elevation="0"
                    >
                    <!-- Project__row-related-projects--card -->
                      <div class="activate-overlay">
                        <div>
                          <template v-if="project.miniature != null">
                            <v-img
                              v-bind:src="
                                'https://www.ofisdesigncms.actstudio.xyz' +
                                project.miniature.path
                              "
                              height="100%"
                              width="100%"
                              class=""
                              :aspect-ratio="16/16"
                            />
                            <!-- activate-overlay__element -->
                          </template>
                        </div>
                        <!-- overlay -->
                        <div
                          class="overlay toClick"
                          v-bind:style="
                            'background-color: ' + project.colorOverlay
                          "
                          v-bind:class="{
                            'yellow-element': project.colorOverlay == '#FFF171',
                          }"
                        >
                          <div
                            class="Project--paragraphs titles capitalize aeonik-bold color-black word-spaces"
                            v-html="project.name"
                            v-bind:class="{
                              'white--text':
                                project.colorOverlay == '#223151' ||
                                project.colorOverlay == '#1F6D5E',
                              'black--text':
                                project.colorOverlay != '#223151' ||
                                project.colorOverlay != '#1F6D5E',
                            }"
                          ></div>
                          <div>
                            <span
                              class="capitalize titles aeonik-light"
                              v-bind:class="{
                                'white--text':
                                  project.colorOverlay == '#223151' ||
                                  project.colorOverlay == '#1F6D5E',
                                'black--text':
                                  project.colorOverlay != '#223151' ||
                                  project.colorOverlay != '#1F6D5E',
                              }"
                              >{{ project.location }}</span
                            >
                          </div>
                          <div>
                            <span
                              class="capitalize titles aeonik-light"
                              v-bind:class="{
                                'white--text':
                                  project.colorOverlay == '#223151' ||
                                  project.colorOverlay == '#1F6D5E',
                                'black--text':
                                  project.colorOverlay != '#223151' ||
                                  project.colorOverlay != '#1F6D5E',
                              }"
                              >{{ numberWithCommas(project.meters) }}m<sup>2</sup></span
                            >
                          </div>
                        </div>
                        <!-- /overlay -->
                      </div>
                    </v-card>
                  </carousel>
               </template>
              </v-col>
            </v-row>
          </v-col>
          <div class="div-float"></div>
        </v-row>
        <!-- **********************/termina proyectos relacionados**************** -->

        <!-- **********************inicia Modal del proyecto**************** -->
        <ProjectModal
          @close="project_modal = false"
          v-bind:visible="project_modal"
          v-bind:projectGet="projectSend"
          v-bind:projectAssetsGet="projectAssets"
          v-bind:projectAssetPath="sendPath"
        ></ProjectModal>
        <!-- **********************/termina Modal del proyecto**************** -->
      </v-container>

      <!-- *****inicia proyectos relacionados**** -->
      <v-container fluid class="d-sm-none" style="overflow: hidden">
        <v-row class="Project__row-related-projects animation-lazy">
          <v-col
            cols="12"
            class="mt-md-5 py-0 pl-8"
            v-bind:class="{
              'opacity-inactive': projectOpacity,
              'opacity-active': projectOpacity == false,
            }"
          >
            <span class="aeonik-bold titles">Proyectos relacionados</span>
          </v-col>
          <v-col
            cols="12"
            class="pl-8 pr-0"
            v-bind:class="{
              'opacity-inactive': projectOpacity,
              'opacity-active': projectOpacity == false,
            }"
          >
            <v-row>
              <v-col cols="12">
                <v-sheet>
                  <v-slide-group
                    v-model="relatedProjectsSlide"
                    v-bind:show-arrows="false"
                    center-active
                  >
                    <v-slide-item
                      v-for="(project, pr) in relatedProjects"
                      v-bind:key="pr + '-mobile'"
                    >
                      <v-card
                        :to="'/project/' + project.url"
                        :ripple="false"
                        class="Project__row-related-projects--card"
                        elevation="0"
                      >
                        <div class="activate-overlay">
                          <div>
                            <template v-if="project.miniature != null">
                              <v-img
                                :src="
                                  'https://www.ofisdesigncms.actstudio.xyz/' +
                                  project.miniature.path
                                "
                                class="activate-overlay__element"
                              ></v-img>
                            </template>
                          </div>
                          <!-- overlay -->
                          <div class="overlay toClick">
                            <div
                              class="Project--paragraphs capitalize titles aeonik-bold word-spaces"
                              v-html="project.name"
                            ></div>
                            <div>
                              <span class="capitalize titles aeonik-light">{{
                                project.location
                              }}</span>
                            </div>
                            <div>
                              <span class="capitalize titles aeonik-light"
                                >{{ numberWithCommas(project.meters) }}m<sup>2</sup></span
                              >
                            </div>
                          </div>
                          <!-- /overlay -->
                        </div>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <!-- *****/termina proyectos relacionados**** -->
    </div>
  </transition>
</template>
<script>
import ProjectModal from "@/components/ProjectModal";
import carousel from "vue-owl-carousel";

export default {
  components: {
    ProjectModal,
    carousel,
  },
  computed: {
    relatedProjects: function () {
      var arrRelatedProjects = this.projects;
      var newArray = [];

      for (let index = 0; index < arrRelatedProjects.length; index++) {
        const element = arrRelatedProjects[index];
        if (element.url != this.project.url) newArray.push(element);
      }

      return arrRelatedProjects;
    },
  },
  data() {
    return {
      render: false,
      project: {},
      projects: [],
      relatedProjectsSlide: 0,
      projectCategories: [],
      projectNext: {
        url: "",
      },
      projectAssets: [],
      projectActive: true,
      projectOpacity: false,

      //for modal
      project_modal: false,
      projectSend: {},
      projectAssetsSend: [],
      sendPath: "",
    }; 
  },
  watch: {
    $route(to, from) {
      this.projectActive = false;
      var aux = this;
      var index = 0;

      //fetch of project
      fetch(
        "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: {
              url: this.$route.params.project,
            },
            populate: 2,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => (this.project = res.entries[0]))
        .then(function () {
          aux.sendAssets();
          aux.projectActive = true;
        });

      //fetch for next project
      fetch(
        "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: {},
            populate: 2,
          }),
        }
      )
        .then((data) => data.json())
        .then(function (data) {
          aux.projects = data.entries;
          index = 0;

          index = aux.projects.findIndex(
            (item) => item["url"] == aux.$route.params.project
          );

          // console.log(index);

          fetch(
            "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
            {
              method: "post",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                skip: index + 1,
                limit: 1,
              }),
            }
          )
            .then((data) => data.json())
            .then(function (data) {
              if (data.entries.length > 0) {
                aux.projectNext = data.entries[0];
              } else {
                aux.projectNext = {
                  url: "",
                };
              }
            });
        })
        .then((res) => (this.render = true));

      //fetch for the categories
      fetch(
        "https://ofisdesigncms.actstudio.xyz/api/collections/get/categoriesProject?token=e96ec97538f5b51ad80b44386e6d1c"
      )
        .then((res) => res.json())
        .then((res) => (this.projectCategories = res.entries));
    },
  },
  beforeMount() {
    var aux = this;
    var index = 0;

    //fetch of project
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {
            url: this.$route.params.project,
          },
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.project = res.entries[0]))
      .then(function () {
        aux.sendAssets();
        aux.projectActive = true;
      });

    //fetch for next project
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {},
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then(function (res) {
        aux.projects = res.entries;

        index = aux.projects.findIndex(
          (item) => item["url"] == aux.$route.params.project
        );

        // console.log(index);

        fetch(
          "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              skip: index + 1,
              limit: 1,
            }),
          }
        )
          .then((data) => data.json())
          .then(function (data) {
            if (data.entries.length > 0) {
              aux.projectNext = data.entries[0];
            } else {
              aux.projectNext = {
                url: "",
              };
            }
          });
      })
      .then((res) => (this.render = true));;

    //fetch for the categories
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/categoriesProject?token=e96ec97538f5b51ad80b44386e6d1c"
    )
      .then((res) => res.json())
      .then((res) => (this.projectCategories = res.entries));
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    this.$emit("update", 1);
  },
  beforeDestroy() {
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    addOpacity: function () {
      this.projectOpacity = true;
    },
    removeOpacity: function () {
      this.projectOpacity = false;
    },
    onResize: function (e) {
      // if (window.innerWidth < 600) {
      //   this.relatedProjectsSlide = 1;
      // }
    },
    isVideo: function (path) {
      return path.substring(path.length - 4) == ".mp4";
    },
    sendProject: function (project) {
      this.projectSend = project;
    },
    sendAssets: function () {
      this.projectAssets = [];

      var projectArray = this.project.content;

      for (var row in projectArray) {
        for (var value in projectArray[row].value) {
          for (var children in projectArray[row].value[value].children) {
            if (
              projectArray[row].value[value].children[children].component ==
              "image"
            ) {
              if (
                projectArray[row].value[value].children[children].settings
                  .image != null
              ) {
                this.projectAssets.push(
                  projectArray[row].value[value].children[children]
                );
              }
            }
          }
        }
      }
    },
    sendPathAsset: function (path) {
      this.sendPath = path;
    },
    selectClass: function (value) {
      if (value < 2) {
        return "animation";
      } else {
        return "animation-lazy";
      }
    },
    numberWithCommas: function(x) {
      return (x || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
};
</script>