var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade-transition"}},[(_vm.projectActive)?_c('div',{staticClass:"Project selector-margin-section"},[_c('v-container',{staticClass:"px-8 px-sm-12 px-md-3 py-0"},[_c('v-row',{staticClass:"animation",staticStyle:{"animation-delay":"1.3s"},attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"Project--line-height-texts",class:{
            'opacity-inactive': _vm.projectOpacity,
            'opacity-active': _vm.projectOpacity == false,
          },attrs:{"cols":"7","sm":"6"}},[_c('div',{staticClass:"Project--paragraphs capitalize titles aeonik-bold word-spaces",domProps:{"innerHTML":_vm._s(_vm.project.name)}}),_c('div',[_c('span',{staticClass:"capitalize titles aeonik-light"},[_vm._v(_vm._s(_vm.project.location))])]),_c('div',[_c('span',{staticClass:"capitalize titles aeonik-light"},[_vm._v(_vm._s(_vm.numberWithCommas(_vm.project.meters))+"m"),_c('sup',[_vm._v("2")])])])]),_c('router-link',{staticClass:"not-text-decoration color-black next-project",staticStyle:{"display":"contents"},attrs:{"to":'/project/' + _vm.projectNext.url}},[(_vm.projectNext.url != '')?_c('v-col',{staticClass:"Project--line-height-texts Project__column-next",class:{
              'opacity-active': _vm.projectOpacity,
              'opacity-inactive': _vm.projectOpacity == false,
            },attrs:{"cols":"5","sm":"6"},on:{"mouseover":_vm.addOpacity,"mouseleave":_vm.removeOpacity}},[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-none d-sm-block"},[_c('div',{staticClass:"Project--paragraphs capitalize titles aeonik-bold word-spaces",domProps:{"innerHTML":_vm._s(_vm.projectNext.name)}}),_c('div',[_c('span',{staticClass:"capitalize titles aeonik-light"},[_vm._v(_vm._s(_vm.projectNext.location))])]),_c('div',[_c('span',{staticClass:"capitalize titles aeonik-light"},[_vm._v(_vm._s(_vm.numberWithCommas(_vm.projectNext.meters))+"m"),_c('sup',[_vm._v("2")])])])])])],1)],1):_vm._e()],1)],1),_c('v-row',{staticClass:"mb-sm-0 mb-md-5 animation",staticStyle:{"animation-delay":"1.3s"}},[_c('v-col',{staticClass:"pt-0 pt-sm-1",class:{
            'opacity-inactive': _vm.projectOpacity,
            'opacity-active': _vm.projectOpacity == false,
          },attrs:{"cols":"auto"}},_vm._l((_vm.project.categories),function(category,cat){return _c('span',{key:cat,staticClass:"Project--categories capitalize aeonik-regular"},[_vm._v(_vm._s(category.name))])}),0)],1),_vm._l((_vm.project.content),function(row,r){return _c('v-row',{key:r,staticClass:"Project__row-content",class:_vm.selectClass(r),style:('animation-delay:1.6s;')},_vm._l((row.value),function(column,col){return _c('v-col',{key:col,class:{
            'opacity-inactive': _vm.projectOpacity,
            'opacity-active': _vm.projectOpacity == false,
          },attrs:{"cols":"12","md":12 / row.value.length}},[_vm._l((column.children),function(children,ch){return [_c('div',{key:ch + '1'},[(children.component == 'text')?[_c('div',{staticClass:"wordBreak Project--paragraphs--content",domProps:{"innerHTML":_vm._s(children.settings.text)}})]:(children.component == 'image')?[_c('div',[(children.settings.image != null)?[(_vm.isVideo(children.settings.image.path))?[_c('div',{staticClass:"position-relative",on:{"click":function($event){(_vm.project_modal = true),
                            _vm.sendProject(_vm.project),
                            _vm.sendPathAsset(children.settings.image.path)}}},[_c('video',{staticClass:"Project__row-content--video toClick",attrs:{"muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"type":"video/mp4","src":'https://www.ofisdesigncms.actstudio.xyz/' +
                              children.settings.image.path}})]),_c('v-img',{staticClass:"Project__row-content--play toClick",attrs:{"src":require("@/assets/icons/play.svg")}})],1)]:[_c('v-img',{staticClass:"Project__row-content--image toClick",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                          children.settings.image.path,"position":"bottom center"},on:{"click":function($event){(_vm.project_modal = true),
                            _vm.sendProject(_vm.project),
                            _vm.sendPathAsset(children.settings.image.path)}}})]]:_vm._e()],2)]:_vm._e()],2)]})],2)}),1)}),_c('v-row',{staticClass:"Project__row-related-projects animation-lazy d-none d-sm-flex mb-7 mb-xl-12 pb-xl-7"},[_c('v-col',{staticClass:"mt-md-5",class:{
            'opacity-inactive': _vm.projectOpacity,
            'opacity-active': _vm.projectOpacity == false,
          },attrs:{"cols":"12"}},[_c('span',{staticClass:"aeonik-bold titles"},[_vm._v("Proyectos relacionados")])]),_c('v-col',{class:{
            'opacity-inactive': _vm.projectOpacity,
            'opacity-active': _vm.projectOpacity == false,
          },attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.render)?[_c('carousel',{attrs:{"items":3,"autoplay":false,"dots":false,"margin":50,"autoHeight":true,"nav":false,"mouseDrag":false,"responsive":{0:{items: 1}, 600:{items: 3}},"slideTransition":"linear"}},_vm._l((_vm.projects),function(project,prc){return _c('v-card',{key:prc,attrs:{"to":'/project/'+project.url,"ripple":false,"tile":"","elevation":"0"}},[_c('div',{staticClass:"activate-overlay"},[_c('div',[(project.miniature != null)?[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz' +
                              project.miniature.path,"height":"100%","width":"100%","aspect-ratio":16/16}})]:_vm._e()],2),_c('div',{staticClass:"overlay toClick",class:{
                          'yellow-element': project.colorOverlay == '#FFF171',
                        },style:('background-color: ' + project.colorOverlay)},[_c('div',{staticClass:"Project--paragraphs titles capitalize aeonik-bold color-black word-spaces",class:{
                            'white--text':
                              project.colorOverlay == '#223151' ||
                              project.colorOverlay == '#1F6D5E',
                            'black--text':
                              project.colorOverlay != '#223151' ||
                              project.colorOverlay != '#1F6D5E',
                          },domProps:{"innerHTML":_vm._s(project.name)}}),_c('div',[_c('span',{staticClass:"capitalize titles aeonik-light",class:{
                              'white--text':
                                project.colorOverlay == '#223151' ||
                                project.colorOverlay == '#1F6D5E',
                              'black--text':
                                project.colorOverlay != '#223151' ||
                                project.colorOverlay != '#1F6D5E',
                            }},[_vm._v(_vm._s(project.location))])]),_c('div',[_c('span',{staticClass:"capitalize titles aeonik-light",class:{
                              'white--text':
                                project.colorOverlay == '#223151' ||
                                project.colorOverlay == '#1F6D5E',
                              'black--text':
                                project.colorOverlay != '#223151' ||
                                project.colorOverlay != '#1F6D5E',
                            }},[_vm._v(_vm._s(_vm.numberWithCommas(project.meters))+"m"),_c('sup',[_vm._v("2")])])])])])])}),1)]:_vm._e()],2)],1)],1),_c('div',{staticClass:"div-float"})],1),_c('ProjectModal',{attrs:{"visible":_vm.project_modal,"projectGet":_vm.projectSend,"projectAssetsGet":_vm.projectAssets,"projectAssetPath":_vm.sendPath},on:{"close":function($event){_vm.project_modal = false}}})],2),_c('v-container',{staticClass:"d-sm-none",staticStyle:{"overflow":"hidden"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"Project__row-related-projects animation-lazy"},[_c('v-col',{staticClass:"mt-md-5 py-0 pl-8",class:{
            'opacity-inactive': _vm.projectOpacity,
            'opacity-active': _vm.projectOpacity == false,
          },attrs:{"cols":"12"}},[_c('span',{staticClass:"aeonik-bold titles"},[_vm._v("Proyectos relacionados")])]),_c('v-col',{staticClass:"pl-8 pr-0",class:{
            'opacity-inactive': _vm.projectOpacity,
            'opacity-active': _vm.projectOpacity == false,
          },attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',[_c('v-slide-group',{attrs:{"show-arrows":false,"center-active":""},model:{value:(_vm.relatedProjectsSlide),callback:function ($$v) {_vm.relatedProjectsSlide=$$v},expression:"relatedProjectsSlide"}},_vm._l((_vm.relatedProjects),function(project,pr){return _c('v-slide-item',{key:pr + '-mobile'},[_c('v-card',{staticClass:"Project__row-related-projects--card",attrs:{"to":'/project/' + project.url,"ripple":false,"elevation":"0"}},[_c('div',{staticClass:"activate-overlay"},[_c('div',[(project.miniature != null)?[_c('v-img',{staticClass:"activate-overlay__element",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                                project.miniature.path}})]:_vm._e()],2),_c('div',{staticClass:"overlay toClick"},[_c('div',{staticClass:"Project--paragraphs capitalize titles aeonik-bold word-spaces",domProps:{"innerHTML":_vm._s(project.name)}}),_c('div',[_c('span',{staticClass:"capitalize titles aeonik-light"},[_vm._v(_vm._s(project.location))])]),_c('div',[_c('span',{staticClass:"capitalize titles aeonik-light"},[_vm._v(_vm._s(_vm.numberWithCommas(project.meters))+"m"),_c('sup',[_vm._v("2")])])])])])])],1)}),1)],1)],1)],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }